import {
  BASE_URL,
  CAPTCHA_MICRO_SERVICE_BASE_URL,
  CLOUDINARY_CONFIG,
  IP_FETCH_URL,
} from "../config/index";
import {
  makePostRequest,
  makeGetRequest,
  uploadFile,
  makePutRequest,
  makeDeleteRequest,
} from "../http-connectors";
import { getToken } from "../interceptors/token-interceptor";

export const login = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      makePostRequest(BASE_URL + "/login", false, {
        ...payload,
        userType: "Influencer",
      })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.log("login call error: ", e);
          reject(e);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const otplessLogin = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      makePostRequest(BASE_URL + "/otp-less-login", false, {
        ...payload,
        userType: "Influencer",
      })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.log("login call error: ", e);
          reject(e);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const connectWithFacebook = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      makePostRequest(BASE_URL + "/social/facebook", true, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.log("login call error: ", e);
          reject(e);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const connectWithGoogle = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      makePostRequest(BASE_URL + "/social/google", true, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.log("login call error: ", e);
          reject(e);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const connectWithApple = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      makePostRequest(BASE_URL + "/social/apple", true, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.log("login call error: ", e);
          reject(e);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getIpData = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(IP_FETCH_URL, false, {}, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getIpData call error: ", e);
        reject(e);
      });
  });
};

export const requestInvitation = (userData) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/invite/self", false, userData)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("requestInvitation call error: ", e);
        reject(e);
      });
  });
};

export const forgotPassword = (handle) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/forgotpassword", false, { handle })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("forgotPassword call error: ", e);
        reject(e);
      });
  });
};

export const requestReferralInvitation = (userData) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/invitation", true, userData)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("forgotPassword call error: ", e);
        reject(e);
      });
  });
};

export const getReferralHistory = (payload) => {
  return new Promise((resolve, reject) => {
    // makePostRequest.......
    makePostRequest(BASE_URL + "/invitations", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("requestInvitation call error: ", e);
        reject(e);
      });
  });
};

export const generateCaptcha = () => {
  return new Promise((resolve, reject) => {
    fetch(CAPTCHA_MICRO_SERVICE_BASE_URL + "/generate", {
      method: "POST",
    })
      .then((res) => {
        console.log("generateCaptcha res :", res);
        resolve(res.json());
      })
      .catch((err) => {
        console.log("generateCaptcha err :", err);
        reject(err);
      });
  });
};

export const validateCaptcha = (key, text) => {
  return new Promise((resolve, reject) => {
    fetch(CAPTCHA_MICRO_SERVICE_BASE_URL + "/validate", {
      method: "POST",
      body: JSON.stringify({ key, text }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log("validateCaptcha res :", res);
        resolve(res.text());
      })
      .catch((err) => {
        console.log("validateCaptcha err :", err);
        reject(err);
      });
  });
};

export const firstStepSignup = (userData, refToken) => {
  return new Promise(async (resolve, reject) => {
    makePostRequest(BASE_URL + "/invitation/signup", true, userData, refToken)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("requestInvitation call error: ", e);
        reject(e);
      });
  });
};

export const updateInfluencerDetails = (userData) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/influencer", true, userData)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("updateInfluencerDetails call error: ", e);
        reject(e);
      });
  });
};

export const getInfluencerDetails = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/influencer", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getInfluencerDetails call error: ", e);
        reject(e);
      });
  });
};

export const cloudinaryImageUpload = (
  imageData,
  type = "image",
  isLargeFile = false
) => {
  console.log("typeof(imageData) :", typeof imageData, type);
  return new Promise((resolve, reject) => {
    const url =
      "https://api.cloudinary.com/v1_1/" +
      CLOUDINARY_CONFIG.API_KEY +
      "/" +
      type +
      "/upload?upload_preset=" +
      CLOUDINARY_CONFIG.UPLOAD_PRESET;
    uploadFile(url, false, imageData, isLargeFile)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const fetchAllThreads = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/chat/threads", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("fetchAllThreads call error: ", e);
        reject(e);
      });
  });
};

export const getMessagesOfAThread = ({
  threadId,
  payload = { limit: 20, skip: 0 },
}) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/chat/thread/${threadId}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getMessagesOfAThread call error: ", e);
        reject(e);
      });
  });
};

export const getAThreadForList = (threadId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/chat/thread/list/" + threadId, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAThreadForList call error", e);
        reject(e);
      });
  });
};

export const createThread = (followerId) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/chat/thread", true, {
      _to: followerId,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("createThread call error: ", e);
        reject(e);
      });
  });
};

export const markThreadAsRead = (threadId) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/chat/read/" + threadId, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const searchMessageByText = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/chat/threads/search", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        resolve(e);
      });
  });
};

export const markFanAsArchive = (threadId, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/chat/thread/" + threadId, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const sendMassBulkMessages = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/chat/bulk", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        resolve(e);
      });
  });
};

export const getFeeds = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/fan/feed", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const postContent = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/influencer/post", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getPosts = (pagination) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/influencer/posts", true, pagination)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("postContent call error: ", e);
        reject(e);
      });
  });
};

export const saveContents = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/content", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("saveContents call error: ", e);
        reject(e);
      });
  });
};

export const editPost = (data, id = null) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      BASE_URL + "/influencer/update/post/" + (id ? id : data.id),
      true,
      data
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("saveContents call error: ", e);
        reject(e);
      });
  });
};

export const getComments = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/post/comments/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getComments call error: ", e);
        reject(e);
      });
  });
};

// type = post / event / ppv
export const getAllSubComments = (id, type = "post", payload = {}) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/${type}/subcomments/` + id, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getSubComments call error: ", e);
        reject(e);
      });
  });
};

export const postCommentOnFeed = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/post/interaction/" + data.id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("postCommentOnFeed call error: ", e);
        reject(e);
      });
  });
};

export const updateComment = (data, id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/post/update/comment/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("updateComment call error: ", e);
        reject(e);
      });
  });
};

export const replyOnComment = (data, id, interactionType = "post") => {
  return new Promise((resolve, reject) => {
    const apiUrl = BASE_URL + `/${interactionType}/interaction/` + id;
    makePostRequest(apiUrl, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("updateComment call error: ", e);
        reject(e);
      });
  });
};

export const deleteComment = (id, interactionType = "post") => {
  return new Promise((resolve, reject) => {
    const apiUrl = BASE_URL + `/${interactionType}/delete/comment/` + id;
    makeDeleteRequest(apiUrl, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("deleteComment call error: ", e);
        reject(e);
      });
  });
};

export const createABroadcast = (broadCastId, text = null) => {
  return new Promise((resolve, reject) => {
    const payload = {
      broadCastId,
    };
    if (text) {
      payload["text"] = text;
    }
    makePostRequest(BASE_URL + "/influencer/broadcast", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const fetchBroadCastStats = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/influencer/broadcast/stats", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const deletPost = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(BASE_URL + "/influencer/delete/post/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("deletPost call error: ", e);
        reject(e);
      });
  });
};

export const updateProfile = (data) => {
  return new Promise((resolve, reject) => {
    data.blockCountries =
      data &&
      data.hasOwnProperty("settings") &&
      data.settings.hasOwnProperty("blockCountries")
        ? data.settings.blockCountries
        : null;
    if (data.blockCountries === null) {
      delete data.blockCountries;
    }
    makePutRequest(BASE_URL + "/influencer", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("updateProfile call error: ", e);
        reject(e);
      });
  });
};

export const fetchContentSummary = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/influencer/summary", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const fetchSepcificContents = ({ payload, type = "image" }) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/influencer/contents/" + type, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const markFanAsFavourite = (fanId) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/influencer/favourite/fan/" + fanId, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const muteFan = (fanId) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/influencer/mute/" + fanId, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const blockFan = (fanId) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/influencer/block/" + fanId, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getAllNotifications = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/influencer/notifications", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const changeInfluencerPassword = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/user/changepassword", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const readAllNotifications = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/influencer/notifications/read", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const readSingleNotification = (id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/influencer/read/notification/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const updateBank = (bankInfo) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/influencer/bank/", true, bankInfo)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getAllLoginSessions = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/user/login/devices", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const closeAllNotifications = (data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/device/logout", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const updateNotesOfFollower = (followerId, notes) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/influencer/notes/" + followerId, true, {
      notes,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getSettings = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/settings", false)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const removeDeviceOnLogout = () => {
  return new Promise(async (resolve, reject) => {
    const authToken = await getToken();
    if (authToken) {
      makePutRequest(BASE_URL + "/device/logout", true, {
        logoutType: "current",
      })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    } else {
      resolve(true);
    }
  });
};

export const getPostDetails = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/influencer/post/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getPendingAndCurrentBalance = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/influencer/balance", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const createWithdrawalRequest = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/withdrawal", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/**
 * get all earnings
 * it is post request
 * also get filter earnings
 *
 * @param {*} filterData: Object / optional
 */
export const getAllEarnings = (filterData) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/influencer/earnings", true, filterData)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getEarningSummaryStatistics = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/influencer/earnings-summary", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getAllTransactionsFilters = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/influencer/transactions/filters", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getAllPayoutRequests = (payload) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/withdrawals", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getEarningStatsDetails = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/influencer/earnings/stats", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getEarningStatistics = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/influencer/earnings/date/stats", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const checkAvailability = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/user/uniques", false, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const kycSubmission = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/influencer/kyc", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getStripeAccountRequirement = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/influencer/stripe/requirement", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getPublicInfluencerDetails = (id, pagination) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/public/influencer/" + id, false, pagination)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("TCL: getPublicInfluencerDetails -> e", e);
        reject(e);
      });
  });
};

export const getProfileDetailsAsFan = (id, pagination) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/influencer/viewas", true, pagination)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("TCL: getProfileDetailsAsFan -> e", e);
        reject(e);
      });
  });
};

export const createVaultFolder = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/influencer/create/folder", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getVaults = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/influencer/get/vault", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("TCL: getVaults -> e", e);
        reject(e);
      });
  });
};

export const updateVaultFolder = (data, id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/influencer/update/folder/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteVaultFolder = (id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/influencer/delete/folder/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateFolderSequence = (payload, id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      BASE_URL + "/influencer/sequence/folder/content/" + id,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getFolderDetails = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/influencer/get/folder/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("TCL: getFolderDetails -> e", e);
        reject(e);
      });
  });
};

export const getFolderContentsDetails = (id, data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/influencer/folder/contents/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("TCL: getFolderContentsDetails -> e", e);
        reject(e);
      });
  });
};

export const postFolderContents = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/folder/content", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteVaultFolderContent = (data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/influencer/delete/content", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const changeContentName = (data, id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/influencer/rename/content/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const searchFans = (searchedTerm) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/influencer/fans/all/" + searchedTerm, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("call error: ", e);
        reject(e);
      });
  });
};

export const likeComment = (id, data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/comment/like/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("TCL: likeComment -> e", e);
        reject(e);
      });
  });
};

export const getAllOffers = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/influencer/offers", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAllOffers call error: ", e);
        reject(e);
      });
  });
};

export const createOffer = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/influencer/offer", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("TCL: createOffer -> e", e);
        reject(e);
      });
  });
};

export const updateOffer = (id, data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/influencer/offers/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("TCL: updateOffer -> e", e);
        reject(e);
      });
  });
};

export const deleteOffer = (data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/influencer/offers/" + data.id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("TCL: updateOffer -> e", e);
        reject(e);
      });
  });
};

// export const createLiveEvent = (data) => {
//   return new Promise((resolve, reject) => {
//     makePostRequest(BASE_URL + "/influencer/event", true, data)
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((e) => {
//         reject(e);
//       });
//   });
// };

export const createLiveEvent = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/influencer/create/event", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const editLiveEvent = (data, id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/influencer/event/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getAllLiveEvents = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/influencer/events", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("call error: ", e);
        reject(e);
      });
  });
};

export const getAllInvitedLiveEvents = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/influencers/event/invited", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("call error: ", e);
        reject(e);
      });
  });
};

export const postCommentOnEvent = (data, id = null) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/event/interaction/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("postCommentOnFeed call error: ", e);
        reject(e);
      });
  });
};

export const getEventDetails = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/influencer/event/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getEventDetails call error: ", e);
        reject(e);
      });
  });
};

export const getEventComments = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/event/comments/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getEventComments call error: ", e);
        reject(e);
      });
  });
};

export const cancelLiveEvent = (id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/influencer/event/cancel/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("cancelLiveEvent error: ", e);
        reject(e);
      });
  });
};

export const getLiveEventDetails = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/influencer/event/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("TCL: getLiveEventDetails -> e", e);
        reject(e);
      });
  });
};

/**
 *
 * @param {string} id - event id
 * @returns
 */
export const getLiveEventStreamingData = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/event/token/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllPPV = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/influencer/payperviews/all", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("TCL: getAllPPV -> e", e);
        reject(e);
      });
  });
};

export const createPPV = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/influencer/payperview", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("createPPV call error: ", e);
        reject(e);
      });
  });
};

export const editPPV = (data, id = null) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/influencer/payperview/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("editPPV call error: ", e);
        reject(e);
      });
  });
};

export const getPPV = (id = null) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/influencer/payperviews/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("editPPV call error: ", e);
        reject(e);
      });
  });
};

export const getPPVComments = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/ppv/comments/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getPPVComments call error: ", e);
        reject(e);
      });
  });
};

export const postCommentOnPPV = (data, id = null) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/ppv/interaction/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("postCommentOnPPV call error: ", e);
        reject(e);
      });
  });
};

export const getAllBundles = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/influencer/bundles", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("AllBundles call error: ", e);
        reject(e);
      });
  });
};

export const createOrUpdate = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/influencer/bundle", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("createOrUpdate bundle call error: ", e);
        reject(e);
      });
  });
};

export const fetchEvent = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/influencer/event/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("fetchEvent call error: ", e);
        reject(e);
      });
  });
};

export const getAllShoutOut = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/shoutOutPricings", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAllShoutOut call error: ", e);
        reject(e);
      });
  });
};

export const createShoutOut = (apiData) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/shoutOutPricing/", true, apiData)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("createShoutOut call error: ", e);
        reject(e);
      });
  });
};

export const editShoutOut = (apiData) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/shoutOutPricing/" + apiData.id, true, apiData)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("createShoutOut call error: ", e);
        reject(e);
      });
  });
};

export const fetchAllShoutOutOrders = (apiData) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/influencer/shoutOutOrders", true, apiData)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("createShoutOut call error: ", e);
        reject(e);
      });
  });
};

export const postShoutOutContent = (apiData) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + "/influencer/shoutOutOrder/content/" + apiData.id,
      true,
      apiData
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("createShoutOut call error: ", e);
        reject(e);
      });
  });
};

export const makeUpdateShoutOutRequest = (apiData) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/shoutOutOrder/" + apiData.id, true, apiData)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("makeUpdateShoutOutRequest call error: ", e);
        reject(e);
      });
  });
};

export const postCommentInShoutOut = (id, text) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/shoutOutOrder/comment/" + id, true, text)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("postCommentInShoutOut call error: ", e);
        reject(e);
      });
  });
};

export const fetchAllShoutoutComment = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/shoutOutOrder/comments/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("postCommentInShoutOut call error: ", e);
        reject(e);
      });
  });
};

export const updateChatShoutoutStartTime = (messageId, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/chat/shoutout/time/${messageId}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("error>>", e);
        reject(e);
      });
  });
};

export const createTeamMember = (memberData) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/influencer/create/assistant", true, memberData)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("createTeamMember call error: ", e);
        reject(e);
      });
  });
};

export const editTeamMember = (memberId, memberData) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/influencer/update/assistant/${memberId}`,
      true,
      memberData
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("createTeamMember call error: ", e);
        reject(e);
      });
  });
};

export const getAllTeamMembers = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/influencers/team/member", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAllTeamMembers call error: ", e);
        reject(e);
      });
  });
};

export const getOneTeamMembersById = (memberId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/influencers/team/member/${memberId}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAllTeamMembers call error: ", e);
        reject(e);
      });
  });
};

/**
 * get influencer followers
 * it is post request
 * also get influencer filter followers
 *
 * @param {*} filterData: Object / optional
 */
export const getInfluencerFollowers = (filterData) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/influencer/followers/list`, true, filterData)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getInfluencerFollowers call error: ", e);
        reject(e);
      });
  });
};

/**
 * summaryType - vault // ppv // event // referral
 * apiData - {startDate: date, endDate: date}
 * it is post request
 *
 * @param {String} summaryType: String / required
 * @param {*} apiData: Object / optional
 *
 */
export const getIndividualEarningStats = (summaryType, apiData) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/summary/${summaryType}`, true, apiData)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAllTeamMembers call error: ", e);
        reject(e);
      });
  });
};

export const getInfluencerBalance = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/influencer/balance", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getMediaContents = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/influencer/library/contents", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getCategorywiseFanEarning = (fanId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/influencer/fan/category/earning/${fanId}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getTransactions = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/element/transactions/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getTemplateName = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/settings/templatename`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const resendReferralInvitation = (userId) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/invitation/resend/${userId}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("forgotPassword call error: ", e);
        reject(e);
      });
  });
};

export const resendReferralInvitationToAll = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/allinvitation/resend`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("error >>> ", e);
        reject(e);
      });
  });
};

/**
 * Adds and Sets payout Default Card
 */
export const addPayoutCardAndMakeDefault = (cardToken) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/influencer/payout/card`, true, {
      cardToken,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/**
 * Adds and Sets payout Default Card
 */
export const getCryptoExchangeRate = (
  baseAsset,
  queryParams,
  customHeaders
) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `https://rest.coinapi.io/v1/exchangerate/${baseAsset}`,
      false,
      queryParams,
      false,
      customHeaders
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/**
 *  Search influencers to mention
 */
export const searchInfluencersToMention = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/influencers/search/all`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/**
 *  Send Notification to mentioned Influencer
 */
export const sendNotificationToMentionedInfluencer = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${BASE_URL}/influencer/notifications/mention`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const createCoupon = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/influencer/create/coupon`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const updateActiveInactiveCoupon = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/influencer/update/coupon/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const deleteCoupon = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(`${BASE_URL}/influencer/delete/coupon/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getAllCoupons = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/influencer/get-all-coupons`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getMessageMediaContents = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/user/media/contents", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getUserKycLink = () => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/influencer/update/kyc", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const accountDelete = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/influencer/delete-account/sheduled", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getUnreadMessageCount = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/chat-thread/unread-count", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// help module api - start

export const getAllFaqTopics = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/faq-topics`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllFaqMiscellaneous = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/faq-miscellaneous`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllFaqCategories = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/faq-categories`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const findFaqCategoryById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/faq-category/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const findFaqTopicById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/faq-topic/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// help module api - end

export const getContentUrlById = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/contents/signed-url`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteMassMessage = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(BASE_URL + `/influencer/delete/broadcast/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getInfluencersList = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/influencers/list`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const reDoUserKycLink = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/influencer/fresh/kyc`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllTags = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/grouplist`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const addTags = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/create/groups`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateGroup = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/edit/group/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteTagById = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(BASE_URL + `/group/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const addFanToGroups = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/groups/add/fans`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllGroupsThreads = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/chat/groups`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getGroupThread = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/fetch/group/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllGroupMessages = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/chat/group/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const sendGroupMessage = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/message/group`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllSubscribers = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/all/subscribers`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllStories = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/my/stories`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const createStory = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/story`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteStory = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(BASE_URL + `/story/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getSubscribersStats = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/top/fans/for/influencer`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getLiveEventInviteCount = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/live/events/unread/count`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const markAllInviteAsRead = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/mark/events/read`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const blockSubscriberFromThread = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/block/fan/thread/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

import React, {
  Fragment,
  Suspense,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import {
  getAllLiveEvents,
  getAllInvitedLiveEvents,
  markAllInviteAsRead,
  getLiveEventInviteCount,
} from "../http-calls";
import {
  showToast,
  errorHandler,
  isPermissionToAccess,
} from "../helper-methods";
import ConfigureString from "../components/ConfigureString";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import SkeletonLoading from "../components/SkeletonLoading";
import ErrorBoundary from "../components/ErrorBoundary";
import CustomLoader from "../components/custom/CustomLoader";

// code splitting
const LiveEventWrapper = React.lazy(() =>
  import("../components/LiveEventWrapper")
);

const LiveEventPage = () => {
  const history = useHistory();

  const observer = useRef();

  const [liveEvents, setLiveEvents] = useState([]);
  const [liveEventsCount, setLiveEventsCount] = useState(0);
  const [unreadInviteCount, setUnreadInviteCount] = useState();
  const [dataPayload, setDataPayload] = useState({
    skip: 0,
    limit: 20,
    hasPastEvents: false,
  });
  const [loadingState, setLoadingState] = useState({
    data: false,
    filter: false,
  });
  const [activeTab, setActiveTab] = useState("upcoming"); // upcoming / past / invited

  const canCreateLiveEvent = useMemo(
    () => isPermissionToAccess("liveEvent", "canCreateLiveEvent", true),
    []
  );

  const canEditOrDeleteLiveEvent = useMemo(
    () => isPermissionToAccess("liveEvent", "canEditOrDeleteLiveEvent", true),
    []
  );

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const _addCommentEvent = (index, isAdded) => {
    try {
      const newLiveEvents = [...liveEvents];
      if (isAdded) {
        if (newLiveEvents?.[index]?.comments) {
          newLiveEvents[index].comments++;
        } else {
          newLiveEvents[index].comments = 1;
        }
      } else {
        if (newLiveEvents?.[index]?.comments) {
          newLiveEvents[index].comments--;
        } else {
          newLiveEvents[index].comments = 0;
        }
      }
      setLiveEvents(newLiveEvents);
    } catch (error) {
      console.log({ error });
    }
  };

  const _cancelEvent = (index) => {
    const newLiveEvents = [...liveEvents];
    if (newLiveEvents[index]) {
      newLiveEvents[index].status = "cancelled";
      setLiveEvents(newLiveEvents);
    }
  };

  const _getAllLiveEvents = async (payload) => {
    try {
      _manageLoadingState("data", true);

      let res;

      if (payload?.hasInvitedEvents) {
        res = await getAllInvitedLiveEvents(payload);
      } else {
        res = await getAllLiveEvents(payload);
      }

      setLiveEvents((prev) =>
        payload?.skip ? prev.concat(res?.events) : res?.events
      );

      setLiveEventsCount(res?.count);

      setLoadingState({});
    } catch (error) {
      errorHandler(error);
      setLoadingState({});
    }
  };

  const _toggleHasPastEvents = (
    hasPastEvents = false,
    hasInvitedEvents = false
  ) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload.skip = 0;
    newDataPayload.hasPastEvents = hasPastEvents;
    newDataPayload.hasInvitedEvents = hasInvitedEvents;
    setDataPayload(newDataPayload);

    _getAllLiveEvents(newDataPayload);
  };

  const _markAllInviteAsRead = async () => {
    try {
      await markAllInviteAsRead();
    } catch (error) {
      errorHandler(error);
    }
  };

  // tabName = upcoming / past / invited
  const _toggleActiveTab = (tabName = "upcoming") => {
    if (tabName === "invited") {
      _markAllInviteAsRead();
    }
    setActiveTab(tabName);

    // store in local storage
    localStorage.liveEventTabName = tabName;

    _toggleHasPastEvents(
      tabName === "past" ? true : false,
      tabName === "invited" ? true : false
    );
  };

  const _updateCommentsCount = ({ index, value }) => {
    try {
      const newLiveEvents = [...liveEvents];

      newLiveEvents[index].comments = value;

      setLiveEvents(newLiveEvents);
    } catch (error) {
      console.log({ error });
    }
  };

  const lastElementRef = useCallback(
    (node) => {
      if (loadingState?.data) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && liveEvents?.length < liveEventsCount) {
          const newDataPayload = { ...dataPayload };
          newDataPayload["skip"] = liveEvents?.length || 0;
          setDataPayload(newDataPayload);
          _getAllLiveEvents(newDataPayload);
        }
      });

      if (node) observer.current.observe(node);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingState?.data, liveEvents]
  );

  const _getUnreadInviteCount = async () => {
    try {
      const res = await getLiveEventInviteCount();
      setUnreadInviteCount(res?.eventsCount);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (!isPermissionToAccess("liveEvent", "canViewLiveEvent")) {
      showToast("Unauthorized", "error");
      history.push("/my-profile");
      return;
    }

    // upcoming / past / invited
    _toggleActiveTab(localStorage?.liveEventTabName || "upcoming");

    _getUnreadInviteCount();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="customPgHeight animated fadeIn">
      <Container className="noPadding">
        <Row className="justify-content-center noMargin">
          <Col sm={12} md={11} lg={8} xl={12} className="noPadding">
            <div className="pgTitle customMarginMob hasCreateBtn">
              <div className="d-flex">
                <h2>
                  <ConfigureString keyString={"event"} />
                </h2>{" "}
                {loadingState?.data ? <CustomLoader className="ml-2" /> : null}
              </div>

              <div className="d-flex">
                {canCreateLiveEvent ? (
                  <Button
                    className="themeBtn addBtn"
                    onClick={() => history.push("/live-event-form")}
                  >
                    <span className="d-none d-md-inline-block">
                      <ConfigureString keyString="Create event" />
                    </span>
                    <img 
                      src="/assets/img/calendar.png" 
                      alt="Calendar" 
                      loading="lazy" 
                      className="d-md-none createIcon" 
                    />
                  </Button>
                ) : null}

                {canEditOrDeleteLiveEvent ? (
                  <Button
                    className="themeBtn addBtn ml-2"
                    onClick={() => history.push("/manage-sticker")}
                  >
                    Manage Cheer Icons
                  </Button>
                ) : null}
              </div>
            </div>

            <Nav tabs className="customTabs tabsLiveEvent">
              <NavItem>
                <NavLink
                  active={activeTab === "upcoming"}
                  onClick={() => {
                    _toggleActiveTab("upcoming");
                  }}
                >
                  <ConfigureString keyString="Upcoming event" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={activeTab === "past"}
                  onClick={() => {
                    _toggleActiveTab("past");
                  }}
                >
                  <ConfigureString keyString="Past event" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={activeTab === "invited"}
                  onClick={() => {
                    _toggleActiveTab("invited");
                  }}
                >
                  <ConfigureString keyString="Invited event" />{" "}
                  {unreadInviteCount ? `(${unreadInviteCount})` : null}
                </NavLink>
              </NavItem>
            </Nav>

            <Row className="liveEventWrapper">
              {liveEvents?.length ? (
                liveEvents.map((each, index) => (
                  <Fragment key={`event_${each?.id}_${index}`}>
                    <ErrorBoundary>
                      <Suspense
                        fallback={<SkeletonLoading type={"event"} count={1} />}
                      >
                        <LiveEventWrapper
                          {...(index === liveEvents.length - 1
                            ? { lastElementRef }
                            : {})}
                          event={each}
                          eventIndex={index}
                          onComment={(index, isAdded) =>
                            _addCommentEvent(index, isAdded)
                          }
                          hasPastEvents={dataPayload.hasPastEvents}
                          hasInvitedEvents={dataPayload.hasInvitedEvents}
                          cancelEvent={(index) => _cancelEvent(index)}
                          updateCommentsCount={({ index, value }) =>
                            _updateCommentsCount({ index, value })
                          }
                          isLoading={loadingState.data}
                        />
                      </Suspense>
                    </ErrorBoundary>

                    {index === liveEvents.length - 1 && loadingState.data && (
                      <SkeletonLoading type={"event"} count={2} />
                    )}
                  </Fragment>
                ))
              ) : loadingState.data ? (
                <SkeletonLoading type={"event"} count={4} />
              ) : (
                <div className="w-100 noContentFound">
                  <ConfigureString keyString={`No ${activeTab} event found`} />
                </div>
              )}
            </Row>
          </Col>
        </Row>

        {/* create btn */}
        {canCreateLiveEvent ? (
          <Button
            className="createBtn"
            onClick={() => history.push("/live-event-form")}
          >
            <img src="/assets/img/calendar.png" alt="Calendar" loading="lazy" />
          </Button>
        ) : null}
      </Container>
    </div>
  );
};

export default LiveEventPage;

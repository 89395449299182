import React, { useEffect, useMemo, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import NewStoryModal from "../components/modals/NewStoryModal";
import ViewStoryModal from "../components/modals/ViewStoryModal";
import StoryPreviewComponent from "../components/StoryPreviewComponent";
import {
  errorHandler,
  isPermissionToAccess,
  showToast,
} from "../helper-methods";
import { deleteStory } from "../http-calls";
import { deleteStoryFromRedux, getAndUpdateStories } from "../redux/actions";
import SkeletonLoading from "../components/SkeletonLoading";

const responsive = {
  desktop: {
    breakpoint: { max: 5000, min: 768 },
    items: 6,
    partialVisibilityGutter: 10,
  },
  miniTablet: {
    breakpoint: { max: 767, min: 576 },
    items: 6,
    partialVisibilityGutter: 5,
  },
  mobile: {
    breakpoint: { max: 575, min: 100 },
    items: 4,
    partialVisibilityGutter: 13,
  },
};

const StoryPage = () => {
  const dispatch = useDispatch();

  const { stories, loading } = useSelector((state) => state?.storiesData || []);

  const canUpdateProfileSettings = useMemo(
    () =>
      isPermissionToAccess(
        "profileAndSettings",
        "canUpdateProfileSettings",
        true
      ),
    []
  );

  const [addStoryModal, setAddStoryModal] = useState({
    isOpen: false,
    data: null,
  });
  const [viewStoryModal, setViewStoryModal] = useState({
    isOpen: false,
    data: null,
  });

  const _toggleViewStoryModal = (isOpen = false, data = null) => {
    setViewStoryModal({ isOpen, data });
  };

  const _toggleAddStoryModal = (isOpen = false, data = null) => {
    setAddStoryModal({ isOpen, data });
  };

  const _deleteStory = (story) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(deleteStoryFromRedux(story));
        showToast("Story has been deleted", "success");

        await deleteStory(story.id || story._id);
      } catch (error) {
        errorHandler(error);
        _getAllStories();
      } finally {
        resolve();
        _toggleViewStoryModal();
      }
    });
  };

  const _getAllStories = async () => {
    try {
      await getAndUpdateStories()(dispatch);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    _getAllStories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="animated fadeIn">
      {canUpdateProfileSettings ? (
        <>
          <Carousel
            key={stories.length}
            responsive={responsive}
            // centerMode={true}
            // additionalTransfrom={-59}
            partialVisible={true}
            className="storyCarousel"
            draggable={false}
            showDots={false}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            renderButtonGroupOutside={true}
            slidesToSlide={5}
            removeArrowOnDeviceType={["mobile"]}
            arrows={true}
            swipeable={true}
          >
            <div>
              {/* clicking this div should open "new story" modal */}
              <div
                className="storyImgWrap"
                onClick={() => _toggleAddStoryModal(true)}
              >
                <i className="fa fa-plus" />
              </div>

              <p>New Story</p>
            </div>

            {stories?.length ? (
              stories?.map(
                (each) => (
                  <StoryPreviewComponent
                    key={each?._id}
                    story={each}
                    toggleViewStoryModal={(data) =>
                      _toggleViewStoryModal(true, data)
                    }
                  />
                )
                // console.log(each)
              )
            ) : loading ? (
              <SkeletonLoading type={"story"} count={5} />
            ) : (
              <></>
            )}
          </Carousel>
        </>
      ) : (
        ""
      )}
      <NewStoryModal
        isOpen={addStoryModal.isOpen}
        toggle={_toggleAddStoryModal}
        isForConfirmation={false}
      />

      <ViewStoryModal
        isOpen={viewStoryModal?.isOpen}
        data={[viewStoryModal?.data]}
        toggle={_toggleViewStoryModal}
        deleteStory={_deleteStory}
      />
    </div>
  );
};

export default StoryPage;

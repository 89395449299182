import React, {
  Fragment,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Container, Row, Col } from "reactstrap";
import ConfigureString from "../components/ConfigureString";
import { useSelector } from "react-redux";
import {
  errorHandler,
  getWindowDimensions,
  isPermissionToAccess,
  showToast,
} from "../helper-methods";
import PayPerViewFilters from "../components/PayPerViewFilters";
import { getAllPPV } from "../http-calls";
import { useHistory } from "react-router-dom";
import moment from "moment";
import SkeletonLoading from "../components/SkeletonLoading";
import ErrorBoundary from "../components/ErrorBoundary";

// code splitting
const PayPerViewWrapper = React.lazy(() =>
  import("../components/PayPerViewWrapper")
);

const PayPerViewPage = () => {
  const history = useHistory();

  const { localFilters } = useSelector((state) => state || {});

  const [filters, setFilters] = useState({
    views: {}, //lower-upper
    status: "",
    postedAfter: "", //date
    duration: {}, //lower-upper
    search: "", //search string
  });
  const [dataPayload, setDataPayload] = useState({
    skip: 0,
    limit: 20,
  });
  const [payPerViews, setPayPerViews] = useState([]);
  const [isOpenFilterCollapse, setIsOpenFilterCollapse] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [loadingState, setLoadingState] = useState({
    data: false,
    filter: false,
    search: false,
  });

  const canCreatePPV = useMemo(
    () => isPermissionToAccess("PPV", "canCreatePPV", true),
    []
  );

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const _updateCommentsCount = ({ index, value }) => {
    try {
      const newPayPerViews = [...payPerViews];

      payPerViews[index].comments = value;

      setPayPerViews(newPayPerViews);
    } catch (error) {
      console.log({ error });
    }
  };

  const observer = useRef();

  const lastElementRef = useCallback(
    (node) => {
      if (loadingState.data) {
        return;
      }

      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          const newDataPayload = { ...dataPayload };
          newDataPayload["skip"] = payPerViews?.length || 0;
          setDataPayload(newDataPayload);
          _getAllPPV(newDataPayload);
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingState.data, hasMore]
  );

  // update comments count only, for local state
  const _addCommentPpv = (index, isAdded) => {
    try {
      const newPayPerViews = [...payPerViews];
      if (isAdded) {
        if (newPayPerViews?.[index]?.comments) {
          newPayPerViews[index].comments++;
        } else {
          newPayPerViews[index].comments = 1;
        }
      } else {
        if (newPayPerViews?.[index]?.comments) {
          newPayPerViews[index].comments--;
        } else {
          newPayPerViews[index].comments = 0;
        }
      }
      setPayPerViews(newPayPerViews);
    } catch (error) {
      console.log({ error });
    }
  };

  const _updateSinglePpv = ({ index, key, value }) => {
    try {
      const newPayPerViews = [...payPerViews];
      if (newPayPerViews?.[index]) {
        newPayPerViews[index][key] = value;
        if (value === "archived") {
          newPayPerViews[index].isArchived = true;
        } else if (value === "published") {
          newPayPerViews[index].isPublished = true;
        }

        if (filters?.status && filters?.status !== value) {
          newPayPerViews.splice(index, 1);
        }

        setPayPerViews(newPayPerViews);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const _getAllPPV = async (payload = { filter: {}, skip: 0, limit: 20 }) => {
    try {
      _manageLoadingState("data", true);

      const res = await getAllPPV(payload);
      setPayPerViews((prev) =>
        payload?.skip ? prev.concat(res.ppvs) : res.ppvs
      );
      setHasMore(payPerViews?.length < res.count ? true : false);

      setLoadingState({});
    } catch (error) {
      setLoadingState({});
      errorHandler(error);
    }
  };

  const _onFilterChange = (filters = {}) => {
    _manageLoadingState("filter", true);

    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = 0;
    newDataPayload["filter"] = {};

    const newFilters = {};

    Object.keys(filters).forEach((key) => {
      switch (key) {
        case "views": {
          if (filters?.[key]?.length) {
            const views = filters?.[key]?.split("-");
            newFilters["views"] = {};
            newFilters.views["lower"] = Number(views[0]);
            if (views?.length > 1 && views?.[1]?.length) {
              newFilters.views["upper"] = Number(views[1]);
            } else {
              delete newFilters.views["upper"];
            }
          }
          break;
        }

        case "duration": {
          if (filters?.[key]?.length) {
            const duration = filters?.[key]?.split("-");
            newFilters["duration"] = {};
            newFilters.duration["lower"] = Number(duration[0]);
            if (duration?.length > 1 && duration?.[1]?.length) {
              newFilters["duration"]["upper"] = Number(duration[1]);
            } else {
              delete newFilters["duration"]["upper"];
            }
          }

          break;
        }

        case "postedAfter": {
          if (filters?.[key]?.length) {
            newFilters["postedAfter"] = filters?.[key]?.length
              ? moment().subtract(Number(filters[key]), "days").toISOString()
              : "";
          }
          break;
        }

        case "status": {
          if (filters?.[key]?.length) {
            newFilters["status"] = filters?.[key];
          }
          break;
        }

        case "search": {
          if (filters?.[key]?.length) {
            newFilters["search"] = filters?.[key];
          }
          break;
        }

        default:
      }
    });

    newDataPayload.filter = newFilters;
    setDataPayload(newDataPayload);

    _getAllPPV(newDataPayload);
  };

  useEffect(() => {
    //check if Assistant have access to view PPV
    if (!isPermissionToAccess("PPV", "canViewPPV")) {
      showToast("Unauthorized", "error");
      history.push("/my-profile");
      return;
    }

    if (localFilters?.filters?.payPerView) {
      const newFilters = { ...filters };
      Object.keys(newFilters).forEach((key) => {
        if (localFilters.filters.payPerView?.[key]) {
          newFilters[key] = localFilters.filters.payPerView[key];
        }
      });
      setFilters(newFilters);
      _onFilterChange(newFilters);
    } else {
      _getAllPPV({ filter: {}, skip: 0, limit: 20 });
    }

    const { screenWidth } = getWindowDimensions();

    if (screenWidth > 768) {
      setIsOpenFilterCollapse(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="customPgHeight animated fadeIn">
      <Container className="noPadding">
        <Row className="justify-content-center noMargin">
          <Col sm={12} md={11} lg={8} xl={12} className="noPadding">
            <div className="pgTitle customMarginMob hasCreateBtn">
              <h2>
                <ConfigureString keyString={"ppv"} />
              </h2>

              <div className="d-flex">
                {canCreatePPV ? (
                  <Button
                    className="themeBtn addBtn mr-2 mr-md-0"
                    onClick={() => history.push("/ppv-form")}
                  >
                    <span className="d-none d-md-inline-block">
                      <ConfigureString keyString="New ppv" />
                    </span>
                    <img 
                      src="/assets/img/calendar.png" 
                      alt="Calendar" 
                      loading="lazy" 
                      className="d-md-none createIcon" 
                    />
                  </Button>
                ) : null}

                <Button
                  onClick={() => setIsOpenFilterCollapse((prev) => !prev)}
                  className="toggleBtn"
                >
                  <img
                    src="/assets/img/filter-icon.png"
                    alt="Filter Icon"
                    height="24"
                    loading="lazy"
                  />
                </Button>
              </div>
            </div>

            {/* Filter Section */}
            <PayPerViewFilters
              isOpenFilterCollapse={isOpenFilterCollapse}
              filters={filters}
              setFilters={setFilters}
              onFilterChange={_onFilterChange}
              manageLoadingState={_manageLoadingState}
            />

            <Row className="liveEventWrapper">
              {payPerViews?.length ? (
                payPerViews.map((each, index) => (
                  <Fragment key={`ppv_${each?.id}_${index}`}>
                    <ErrorBoundary>
                      <Suspense
                        fallback={<SkeletonLoading type={"event"} count={1} />}
                      >
                        <PayPerViewWrapper
                          key={each?._id}
                          ppv={each}
                          ppvIndex={index}
                          getAllPPV={() => _getAllPPV()}
                          onComment={(index, isAdded) =>
                            _addCommentPpv(index, isAdded)
                          }
                          updateSinglePpv={({ index, key, value }) =>
                            _updateSinglePpv({ index, key, value })
                          }
                          {...(index === payPerViews.length - 1
                            ? { lastElementRef }
                            : {})}
                          updateCommentsCount={({ index, value }) =>
                            _updateCommentsCount({ index, value })
                          }
                        />
                      </Suspense>
                    </ErrorBoundary>

                    {index === payPerViews.length - 1 && loadingState.data && (
                      <SkeletonLoading type={"event"} count={2} />
                    )}
                  </Fragment>
                ))
              ) : loadingState.data ? (
                <SkeletonLoading type={"event"} count={4} />
              ) : (
                <div className="noContentFound w-100">
                  <ConfigureString keyString="No ppv found" />
                </div>
              )}
            </Row>
          </Col>
        </Row>

        {/* create btn */}
        {canCreatePPV ? (
          <Button
            className="createBtn"
            onClick={() => history.push("/ppv-form")}
          >
            <img
              src="/assets/img/calendar.png"
              alt="Calendar"
              loading="lazy"
              style={{ width: 27 }}
            />
          </Button>
        ) : null}
      </Container>
    </div>
  );
};

export default PayPerViewPage;

import React, { useEffect, useState } from "react";
import {
  Button,
  CustomInput,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  errorHandler,
  showToast,
  uploadFileOnServer,
} from "../../helper-methods";
import ButtonSpinner from "../ButtonSpinner";
import MediaLibraryModal from "./MediaLibraryModal";
import { createStory } from "../../http-calls";
import { useDispatch } from "react-redux";
import { getAndUpdateStories } from "../../redux/actions";
import ImageEditor from "../ImageEditor";
import CustomVideoPlayer from "../custom/CustomVideoPlayer";

const NewStoryModal = ({
  isOpen,
  data,
  category,
  toggle = () => {},
  onSuccess = () => {},
  onDismiss = () => {},
  isForConfirmation = true,
}) => {
  const dispatch = useDispatch();

  const [formFields, setFormFields] = useState({
    text: "",
    storyMedia: {
      contentType: "",
      previewBlob: "",
      url: "",
      uploadData: null,
    },
    storyFor: "all", // enum=["all", "subscribers"]
    category: "general", // enum=["post", "live event", "vault", "ppv", "general"]
    postId: null,
    eventId: null,
    vaultId: null,
    ppvId: null,
  });
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [mediaLibraryModal, setMediaLibraryModal] = useState({
    isOpen: false,
    data: null,
  });
  const [warning, setWarning] = useState({});
  const [newBlobUrl, setNewBlobUrl] = useState(null);

  const _resetState = () => {
    setNewBlobUrl(null);
    setFormFields({
      text: "",
      storyMedia: {
        contentType: "",
        previewBlob: "",
        url: "",
        uploadData: null,
      },
      storyFor: "all",
      category: "general",
      postId: null,
      eventId: null,
      vaultId: null,
      ppvId: null,
    });
    setErrors({});
    setIsDirty({});
    setLoading(false);
    setWarning({});
  };

  const _onDismiss = () => {
    setNewBlobUrl(null);
    _closeModal();
    onDismiss();
  };

  const _closeModal = () => {
    setNewBlobUrl(null);
    _resetState();
    toggle();
  };

  const _onChageFormField = (key = "", value = "") => {
    setFormFields((prev) => ({ ...prev, [key]: value }));
  };

  const _onImageEditing = (key = "", value = "", blobBytes = null) => {
    setNewBlobUrl(value);
    setFormFields((prevFormFields) => ({
      ...prevFormFields,
      storyMedia: {
        ...prevFormFields.storyMedia,
        [key]: value,
        url: undefined,
        uploadData: blobBytes,
      },
    }));
  };

  const _renderPreview = (newFormFields) => {
    if (
      !newFormFields?.storyMedia?.previewBlob &&
      !newFormFields?.storyMedia?.url
    ) {
      return errorHandler();
    }
    switch (newFormFields?.storyMedia?.contentType) {
      case "image": {
        return (
          <img
            alt="Story Img"
            className="storyImg"
            src={
              formFields?.storyMedia?.url || formFields?.storyMedia?.previewBlob
            }
          />
        );
      }

      case "video": {
        return (
          <CustomVideoPlayer
            src={
              formFields?.storyMedia?.url || formFields?.storyMedia?.previewBlob
            }
            hlsLink={formFields?.storyMedia?.hlsLink}
            controls
          />
        );
      }

      default: {
        return <></>;
      }
    }
  };

  const _onChangeFile = (storyMedia) => {
    const newFormFields = { ...formFields };
    const contentType = storyMedia?.[0]?.contentType;
    if (contentType === "video") {
      // check video duraation
      if (storyMedia?.[0]?.duration > 15) {
        setWarning({
          video: "Video is too long, It'll be trimmed to 15 seconds.",
        });
      } else {
        setWarning({});
      }
    }

    newFormFields.storyMedia = { ...storyMedia?.[0] };
    setFormFields(newFormFields);
    _validateFormfields({ newFormFields, isDirty });
  };

  const _validateFormfields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      let isFormValid = true;
      const newErrors = {};

      Object.keys(newFormFields).forEach((key) => {
        if (newIsDirty?.[key]) {
          switch (key) {
            case "storyMedia": {
              if (
                newFormFields?.[key]?.previewBlob?.trim().length ||
                newFormFields?.[key]?.url?.trim().length
              ) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }

            case "text": {
              if (newFormFields?.[key]?.trim().length) {
                if (newFormFields?.[key]?.trim().length > 80) {
                  newErrors[key] = "*Maximum 80 characters are allowed.";
                  isFormValid = false;
                } else {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                }
              } else {
                newErrors[key] = null;
                newIsDirty[key] = false;
              }
              break;
            }

            default:
          }
        }
      });

      setIsDirty((prev) => ({
        ...prev,
        ...newIsDirty,
      }));
      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));
      resolve(isFormValid);
    });
  };

  const _markAllIsDirty = () => {
    const newFormFields = { ...formFields };
    const newIsDirty = { ...isDirty };
    Object.keys(newFormFields).forEach((key) => {
      newIsDirty[key] = true;
    });
    setIsDirty(newIsDirty);
    return newIsDirty;
  };

  const _categoryIdType = (category) => {
    if (!category) return;

    switch (category) {
      case "post": {
        return "postId";
      }
      case "event":
      case "live event": {
        return "eventId";
      }
      case "vault": {
        return "vaultId";
      }
      case "ppv": {
        return "ppvId";
      }

      default:
        break;
    }
  };

  const _createPayload = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const newFormFields = { ...formFields };
        const payload = {};

        payload["text"] = newFormFields?.text?.trim();
        payload["storyFor"] = newFormFields?.storyFor;

        let uploadedFile = {};
        if (
          newFormFields?.storyMedia?.previewBlob &&
          !newFormFields?.storyMedia?.url
        ) {
          uploadedFile = await uploadFileOnServer(
            [newFormFields?.storyMedia],
            true
          );
        }

        if (newFormFields?.storyMedia?.contentType === "image") {
          payload["previewImageUrl"] =
            uploadedFile?.[0]?.url || newFormFields?.storyMedia?.url;
        } else if (newFormFields?.storyMedia?.contentType === "video") {
          payload["videoUrl"] =
            uploadedFile?.[0]?.url || newFormFields?.storyMedia?.url;
          payload["duration"] = uploadedFile?.[0]?.duration;
        }

        payload["category"] = category || "general";
        if (category && category !== "general") {
          payload[`${_categoryIdType(category)}`] = data?.id || data?._id;
        }

        resolve(payload);
      } catch (error) {
        reject(error);
      }
    });
  };

  const _onSubmit = async () => {
    try {
      setLoading(true);
      const newFormFields = { ...formFields };
      const newIsDirty = _markAllIsDirty();

      const isFormValid = await _validateFormfields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) {
        return;
      }

      const payload = await _createPayload();

      // call post story api
      await createStory(payload);

      // get and update stories on redux
      await getAndUpdateStories()(dispatch);

      showToast("Story has been posted", "success");
      onSuccess();
      _closeModal();
    } catch (error) {
      errorHandler(error);
      _closeModal();
    } finally {
      setLoading(false);
    }
    setNewBlobUrl(null);
  };

  const _toggleMediaLibraryModal = (isOpen = false, data) => {
    setMediaLibraryModal({ isOpen, data });
  };

  const _onDropFile = (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();

      // Get the files that were dropped
      const files = e.dataTransfer.files;

      _toggleMediaLibraryModal(true, files);
    } catch (error) {
      console.log({ error });
    }
  };

  const _createStoryMediaDataByCategory = (category, data) => {
    if (!category || !data?.id) {
      return {
        contentType: "",
        previewBlob: "",
        url: "",
        uploadData: null,
      };
    }

    // each type of posted content might have different structure
    switch (category) {
      case "ppv":
      case "live event":
      case "event": {
        if (data?.coverImage) {
          return {
            contentType: "image",
            previewBlob: null,
            url: data?.coverImage,
            uploadData: null,
          };
        }
        break;
      }

      case "vault": {
        if (data?.preview) {
          return {
            contentType: "image",
            previewBlob: null,
            url: data?.preview,
            uploadData: null,
          };
        }
        break;
      }

      case "post": {
        return {
          contentType: "",
          previewBlob: null,
          url: "",
          uploadData: null,
        };
      }

      default: {
        return {
          contentType: "",
          previewBlob: "",
          url: "",
          uploadData: null,
        };
      }
    }
  };

  const _preFillData = (data) => {
    const newFormFields = { ...formFields };
    newFormFields.category = category || "general";
    if (category && category !== "general") {
      newFormFields[`${_categoryIdType(category)}`] = data?.id || data?._id;
    }
    newFormFields.storyMedia = _createStoryMediaDataByCategory(category, data);
    setFormFields(newFormFields);
  };

  useEffect(() => {
    if (isOpen && isForConfirmation) {
      _preFillData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, isForConfirmation, data]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalHeader toggle={() => _closeModal()}>
        Create Story {isForConfirmation ? " for this content!" : ""}
      </ModalHeader>

      <ModalBody
        onDragOver={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDrop={(e) => {
          _onDropFile(e);
        }}
      >
        <FormGroup>
          <Button className="d-none" disabled={loading} />

          {formFields?.storyMedia?.contentType ? (
            formFields?.storyMedia?.contentType === "image" ? (
              <div>
                {newBlobUrl ? (
                  <Label className="storyImgUpload">
                    {_renderPreview(formFields)}
                  </Label>
                ) : (
                  <ImageEditor
                    image={
                      formFields.storyMedia.previewBlob ||
                      formFields.storyMedia.url
                    }
                    _onImageEditing={_onImageEditing}
                  />
                )}
              </div>
            ) : (
              <Label className="storyImgUpload">
                {_renderPreview(formFields)}
              </Label>
            )
          ) : (
            <Label
              className="storyImgUpload"
              onClick={() => _toggleMediaLibraryModal(true)}
            >
              <div>
                <img
                  src="/assets/img/upload.png"
                  alt="Upload"
                  className="uploadImg"
                  loading="lazy"
                />
                Upload Image/Video
              </div>
            </Label>
          )}

          {errors?.storyMedia ? (
            <div className="validation-error">{errors?.storyMedia}</div>
          ) : null}
          {warning?.video ? (
            <p className="text-warning">{warning.video}</p>
          ) : null}
        </FormGroup>

        <FormGroup>
          <Label>Caption</Label>
          <Input
            type="text"
            placeholder="Write caption..."
            value={formFields?.text}
            onChange={(e) => _onChageFormField("text", e.target.value)}
          />
          {errors?.text ? (
            <div className="validation-error">{errors?.text}</div>
          ) : (
            <></>
          )}
        </FormGroup>

        <FormGroup check className="radio">
          <CustomInput
            className="form-check-input"
            id={`new_story_all`}
            type="radio"
            name="new_story"
            checked={formFields?.storyFor === "all"}
            value={"all"}
            onChange={() => _onChageFormField("storyFor", "all")}
            label={"All (Non-subscribers will also be able to see this story)"}
          />

          <CustomInput
            className="form-check-input"
            id={`new_story_subscribers`}
            type="radio"
            name="new_story"
            checked={formFields?.storyFor === "subscribers"}
            value={"subscribers"}
            onChange={() => _onChageFormField("storyFor", "subscribers")}
            label={"Subscribers Only"}
          />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button
          className="modalBtnCancel"
          onClick={() => (isForConfirmation ? _onDismiss() : _closeModal())}
        >
          Cancel
        </Button>

        <Button
          className="modalBtnSave"
          onClick={() => _onSubmit()}
          disabled={loading}
        >
          {loading ? <ButtonSpinner /> : null} Post
        </Button>
      </ModalFooter>

      <MediaLibraryModal
        isOpen={mediaLibraryModal.isOpen}
        toggle={() => _toggleMediaLibraryModal()}
        dropFileData={mediaLibraryModal.data}
        uploadContents={(contents) => {
          _toggleMediaLibraryModal();
          _onChangeFile(contents);
        }}
        isMultipleUpload={false}
        isMultipleImages={false}
        isMultipleVideos={false}
        isMultipleAudios={false}
        isVideoUpload={true}
        isAudioUpload={false}
        isImageUpload={true}
        isUploadLimit={true}
        videoLimit={1}
        imageLimit={1}
        uploadedFiles={[]}
        isWelcomeMessage={true}
      />
    </Modal>
  );
};

export default NewStoryModal;
